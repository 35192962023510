import React, { Component } from "react";
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { Water } from 'three/examples/jsm/objects/Water.js';

import * as dg from 'dis-gui';
import WaterNormals from "assets/waternormals.jpg";
import Brick from "assets/brick.jpeg";
import Logo from "assets/logo_long_dark_bg.png";
import { Button,Fab } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

class Three extends Component {
    constructor(props){
        super(props);
        this.state={
            boatInitialX:0,
            boatInitialY:5,
            boatInitialZ:0,
            xFrequency:1,
            yFrequency:1,
            zFrequency:1,
            xAmplitude:1,
            yAmplitude:1,
            zAmplitude:1,
            yRotationRate:0.001,
            isMobile: window.innerWidth <= 760,
        };
        this.time=1;
        
        this.timeOfDay="sunset";
        
        this.distance = 400000;
        this.inclination = 0.49; //0-0.5 
        this.azimuth = 0.205;

        this.initScene = this.initScene.bind(this);
        this.animate = this.animate.bind(this);
        this.updateValues = this.updateValues.bind(this);
      }

      componentDidMount() {
        this.initScene();
        this.animate();
      }


      initScene(){
        this.scene = new THREE.Scene();
        
        // Init Camera
        this.camera = new THREE.PerspectiveCamera( 50, window.innerWidth/window.innerHeight,  10, 2000000 );
        this.camera.position.set( 0, 15, 60 );
        this.camera.up.set(0, 1, 0);
        this.camera.lookAt(0, 0, 0);
        
        // Set Renderer
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.mount.appendChild( this.renderer.domElement );

        // Set Controls
        this.controls = new OrbitControls( this.camera, this.renderer.domElement );
        this.controls.maxDistance=700;
        this.controls.maxPolarAngle=Math.PI * 0.45;

        
        // Add Sky
        this.sky = new Sky();
        this.sky.scale.setScalar( 450000 );
        this.sky.material.uniforms.turbidity.value = 10;
        this.sky.material.uniforms.rayleigh.value = 2;
        this.sky.material.uniforms.mieCoefficient.value = 0.005;
        this.sky.material.uniforms.mieDirectionalG.value = 0.8;
        this.sky.material.uniforms.luminance.value = 1;
        

        // Add Sun Helper
        this.sunSphere = new THREE.Mesh(
            new THREE.SphereBufferGeometry( 20000, 16, 8 ),
            new THREE.MeshBasicMaterial( { color: 0xffffff } )
        );
        this.sunSphere.position.y = - 700000;
                
        
        var theta = Math.PI * ( this.inclination - 0.5 );
        var phi = 2 * Math.PI * ( this.azimuth - 0.5 );

        this.sunSphere.position.x = this.distance * Math.cos( phi );
        this.sunSphere.position.y = this.distance * Math.sin( phi ) * Math.sin( theta );
        this.sunSphere.position.z = this.distance * Math.sin( phi ) * Math.cos( theta );
        this.sunSphere.visible=false;
        
        this.sky.material.uniforms.sunPosition.value.copy(this.sunSphere.position);
        
        this.scene.add( this.sky );
        this.scene.add( this.sunSphere );
        
        // Add directional light
        this.light = new THREE.DirectionalLight( 0xffffff, 10 );        
        this.light.position.copy(this.sunSphere.position);
        this.scene.add( this.light );

        // Add ambiant light
        this.ambiantLight = new THREE.AmbientLight( 0xffffff,3 );
        this.scene.add( this.ambiantLight);

        // Init water
        this.waterGeometry = new THREE.PlaneBufferGeometry( 10000, 10000 );
        this.water = new Water(
          this.waterGeometry,
          {
            textureWidth: 512,
            textureHeight: 512,
            waterNormals: new THREE.TextureLoader().load( WaterNormals, function ( texture ) {
    
              texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

    
            } ),
            alpha: 1.0,
            sunDirection: this.sunSphere.position.clone().normalize(),
            sunColor: 0xffffff,
            waterColor: 0x001e0f,
            distortionScale: 3.7,
            fog: this.scene.fog !== undefined
          }
        );

        this.water.rotation.x = - Math.PI / 2;
        this.scene.add(this.water);

        // Load boat
        var loader = new GLTFLoader();
        loader.load("models/boat/scene.gltf",(gltf)=>{
            this.boat = gltf.scene;
            this.boat.position.set(this.state.boatInitialX,this.state.boatInitialY,this.state.boatInitialZ);
            this.boat.scale.set(20,20,20);
            this.boat.rotation.y=0.25;         
            this.scene.add(this.boat);
        });

      }


      animate(){
        requestAnimationFrame( this.animate );
        this.time+=1;
        this.controls.update();
        this.water.material.uniforms.time.value += 1.0 / 60.0;

        // animate boat
        let boatXRotation = Math.sin(this.time*this.state.xFrequency**4/33.3333)*this.state.xAmplitude**4/20;
        let boatYTranslation = Math.sin(this.time*this.state.yFrequency**4/33.3333)*this.state.yAmplitude**4*-1;
        let boatZRotation = Math.sin(this.time*this.state.zFrequency**4/33.3333)*this.state.zAmplitude**5/20;

        if(this.boat){
            this.boat.position.set(this.state.boatInitialX,this.state.boatInitialY,this.state.boatInitialZ);
            this.boat.rotation.x=boatXRotation;
            this.boat.position.y=boatYTranslation+this.state.boatInitialY;
            this.boat.rotation.z=boatZRotation;
            this.boat.rotation.y+=this.rotateBoat? this.state.yRotationRate:0;

            let prevInclination = this.inclination;
            // Update Sun
            switch(this.timeOfDay){
                case "sunset":
                    this.inclination=0.49;
                    break;
                case "day":
                    this.inclination=0.0;
                    break;
                case "night":
                    this.inclination=0.513; 
                    break;
                default:
                    break;
            }
            if(prevInclination!==this.inclination){
                var theta = Math.PI * ( this.inclination - 0.5 );
                var phi = 2 * Math.PI * ( this.azimuth - 0.5 );
        
                this.sunSphere.position.x = this.distance * Math.cos( phi );
                this.sunSphere.position.y = this.distance * Math.sin( phi ) * Math.sin( theta );
                this.sunSphere.position.z = this.distance * Math.sin( phi ) * Math.cos( theta );
                this.sky.material.uniforms.sunPosition.value.copy(this.sunSphere.position);
            }

        }

        this.renderer.render( this.scene, this.camera );
      }

      updateValues(key,value){
          console.log(value)
        this[key]=value;  
        this.setState({
            [key]:value,
        });
      }

      render() {
          return(
              <React.Fragment>
                  <div ref={ref => (this.mount = ref)} ></div>
                  {!this.state.isMobile&&
                    <dg.GUI expanded={!this.state.isMobile}>
                        <dg.Number label='Y Frequency' 
                        min={0}
                        max={2}
                        value={this.state.yFrequency} 
                        onChange={value=>this.updateValues("yFrequency",value)}/>
                        <dg.Number label='Y Amplitude' 
                        min={0}
                        max={2}
                        value={this.state.yAmplitude} 
                        onChange={value=>this.updateValues("yAmplitude",value)}/>
                        <dg.Number label='X Frequency' 
                        min={0}
                        max={2}
                        value={this.state.xFrequency} 
                        onChange={value=>this.updateValues("xFrequency",value)}/>
                        <dg.Number label='X Amplitude' 
                        min={0}
                        max={2}
                        value={this.state.xAmplitude} 
                        onChange={value=>this.updateValues("xAmplitude",value)}/>
                        <dg.Number label='Z Frequency' 
                        min={0}
                        max={2}
                        value={this.state.zFrequency} 
                        onChange={value=>this.updateValues("zFrequency",value)}/>
                        <dg.Number label='Z Amplitude' 
                        min={0}
                        max={2}
                        value={this.state.zAmplitude} 
                        onChange={value=>this.updateValues("zAmplitude",value)}/>
                        <dg.Checkbox label='Rotate Boat'      
                        checked={this.rotateBoat} 
                        onChange={value=>this.rotateBoat=value}/>
                        <dg.Select 
                        label='Time' 
                        options={['Sunset', 'Day', 'Night' ]}
                        onChange={value=>this.timeOfDay=value.toLowerCase()}
                        />              
                    </dg.GUI>
                  }
                <div style={{
                    position:"absolute",
                    top:0,
                    left:0,
                    zIndex:12,
                    padding:"20px 20px",                 
                    color:"white",
                    fontFamily:"'Jura', sans-serif",
                    fontSize:40,
                }}>
                    Sine Explorer
                </div>
                <div style={{
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    bottom: 0,
                    right: 0,
                    left:0,
                    textAlign: "center",
                    zIndex:12,
                    color:"white",
                    fontFamily:"'Jura', sans-serif",
                    fontSize:this.state.isMobile?10:13,
                }}>
                    <a href="https://sketchfab.com/models/3e9093b6f0ce474da4d8cfea7e01f420" target="_blank" rel="noopener noreferrer" style={{color:"white",textDecoration:"none",}}>"Victorian Row Boat" </a>
                    by <a href="https://sketchfab.com/AshleyAslett" target="_blank" rel="noopener noreferrer" style={{color:"white",textDecoration:"none",}}>Ashley Aslett</a>
                     is licensed under <a href="http://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer" style={{color:"white",textDecoration:"none",}}>CC BY 4.0</a>                    
                </div>
                {this.state.isMobile&&
                    <div style={{
                        position:"absolute",
                        bottom:130,
                        zIndex:12,
                        display:"flex",  
                        justifyContent:"flex-start",
                        width:"100%",   
                    }}>
                        <Fab 
                        color="primary" 
                        aria-label="add"
                        onClick={()=>this.updateValues("yAmplitude",this.state.yAmplitude+0.10)}
                        style={{
                            marginLeft:20,
                        }}
                        >
                            <KeyboardArrowUpIcon/>
                        </Fab>
                        <Fab 
                        color="primary" 
                        aria-label="add"
                        onClick={()=>this.updateValues("yAmplitude",this.state.yAmplitude-0.10)}
                        style={{
                            marginLeft:20,
                        }}
                        >
                            <KeyboardArrowDownIcon/>
                        </Fab>
                        <Fab 
                        color="primary" 
                        aria-label="add"
                        onClick={()=>this.updateValues("yFrequency",this.state.yFrequency+0.10)}
                        style={{
                            marginLeft:"auto",
                        }}
                        >
                            <KeyboardArrowUpIcon/>
                        </Fab>
                        <Fab 
                        color="primary" 
                        aria-label="add"
                        onClick={()=>this.updateValues("yFrequency",this.state.yFrequency-0.10)}
                        style={{
                            marginLeft:20,
                            marginRight:20,
                        }}
                        >
                            <KeyboardArrowDownIcon/>
                        </Fab>
                    </div>
                }
                <div style={{
                    position:"absolute",
                    bottom:this.state.isMobile?70:20,
                    right:0,
                    zIndex:12,
                    display:"flex",  
                    justifyContent:this.state.isMobile?"center":"flex-end",
                    width:this.state.isMobile?"100%":"",
                }}>

                <div style={{
                        display:"flex",  
                        flexDirection:"column",
                        color:"white",
                        fontFamily:"'Jura', sans-serif",
                        fontSize:this.state.isMobile?30:40,
                        marginRight:this.state.isMobile?0:30,
                    }}>
                        {!this.state.isMobile&&
                            <div>
                                Boat Height
                            </div>
                        }
                        <div>
                            y&nbsp;=&nbsp;
                            <input
                            type="number"
                            onChange={event=>this.updateValues("yAmplitude",Number(event.target.value))}
                            value={this.state.yAmplitude.toFixed(2)}
                            step={0.01}
                            minValue={0}
                            style={{
                                backgroundColor:"transparent",
                                border:"none",
                                color:"white",
                                opacity:1,
                                fontFamily:"'Jura', sans-serif",
                                fontSize:this.state.isMobile?30:40,
                                width:this.state.isMobile?60:80,
                                appearance:"none",
                                "::-webkit-inner-spin-button":{
                                    appearance:this.state.isMobile?"inherit":"none",
                                }
                            }}
                            disabled={this.state.isMobile}
                            />
                            sin(&nbsp;
                            <input
                            type="number"
                            onChange={event=>this.updateValues("yFrequency",Number(event.target.value))}
                            value={this.state.yFrequency.toFixed(2)}
                            step={0.01}
                            minValue={0}
                            style={{
                                backgroundColor:"transparent",
                                border:"none",
                                color:"white",
                                opacity:1,
                                fontFamily:"'Jura', sans-serif",
                                fontSize:this.state.isMobile?30:40,
                                width:this.state.isMobile?60:80,
                                appearance:"none",
                                "::-webkit-inner-spin-button":{
                                    appearance:"none",
                                }
                            }}
                            disabled={this.state.isMobile}
                            />
                            x&nbsp;)
                        </div>
                    </div>

                </div>
                
                <div style={{
                    position:"absolute",
                    bottom:this.state.isMobile?25:20,
                    marginLeft:this.state.isMobile?0:30,
                    zIndex:12,
                    display:"flex",  
                    justifyContent:this.state.isMobile?"center":"flex-start",
                    width:this.state.isMobile?"100%":"",          
                }}>
                    <div> 
                        <a href="https://aisoft.dev/" target="_blank" rel="noopener noreferrer">
                            <img src={Logo} alt="aisoft" style={{
                                width:this.state.isMobile?125:200,
                                height:this.state.isMobile?42.36:67.78,
                            }}/>
                        </a>
                    </div>

                </div>
                {/* {this.state.isMobile &&
                    <div style={{
                        position:"absolute",
                        top:0,
                        left:0,
                        width:"100vw",
                        height:"100vh",
                        zIndex:15,
                        display:"flex",
                        justifyContent:"center",
                        padding:"0px 20px",
                        backgroundColor:"#2d2d2dc7",
                        color:"white",
                        fontFamily:"'Jura', sans-serif",           
                    }}>
                        <h2 style={{
                            marginTop:75,
                            marginRight:10,
                            marginLeft:10,
                            maxWidth:700,
                            textAlign:"center",
                        }}>
                            This demo is not designed for mobile. Please view it on desktop or tablet.
                        </h2>
                    </div>
                } */}

              </React.Fragment>
          );
      }
}

export  {Three};
import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Three} from "components/threejs.component.js";

function App() {
  return (
    <React.Fragment>
      <Three/>
    </React.Fragment>
  );
}

export default App;
